export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const SET_USERS_PAGE = 'SET_USERS_PAGE';
export const SET_USER_SINGLE = 'SET_USER_SINGLE';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_SINGLE_USER_LOADING = 'SET_SINGLE_USER_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const SET_SINGLE_USER = 'SET_SINGLE_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
