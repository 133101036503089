export const GET_STAFF = 'GET_STAFF';
export const SET_STAFF = 'SET_STAFF';
export const SET_STAFF_PAGE = 'SET_STAFF_PAGE';
export const SET_STAFF_LOADING = 'SET_STAFF_LOADING';
export const SET_STAFF_DETAILS = 'SET_STAFF_DETAILS';
export const GET_STAFF_DETAILS = 'GET_STAFF_DETAILS';
export const CREATE_STAFF = 'CREATE_STAFF';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const GET_STAFF_SINGLE = 'GET_STAFF_SINGLE';
export const SET_STAFF_SINGLE = 'SET_STAFF_SINGLE';
export const DELETE_STAFF = 'DELETE_STAFF';
// appointments
export const GET_STAFF_APPOINTMENTS = 'GET_STAFF_APPOINTMENTS';
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const SET_APPOINTMENTS_PAGE = 'SET_APPOINTMENTS_PAGE';
export const SET_APPOINTMENTS_LOADING = 'SET_APPOINTMENTS_LOADING';
export const SET_APPOINTMENTS_ORDER = 'SET_APPOINTMENTS_ORDER';
// appointments reminders
export const GET_APPOINTMENTS_REMINDERS = 'GET_APPOINTMENTS_REMINDERS';
export const SET_APPOINTMENTS_REMINDERS = 'SET_APPOINTMENTS_REMINDERS';
export const SET_APPOINTMENTS_REMINDERS_PAGE =
  'SET_APPOINTMENTS_REMINDERS_PAGE';
export const SET_APPOINTMENTS_REMINDERS_LOADING =
  'SET_APPOINTMENTS_REMINDERS_LOADING';
export const SET_APPOINTMENTS_REMINDERS_ORDER =
  'SET_APPOINTMENTS_REMINDERS_ORDER';
export const CANCEL_APPOINTMENT_REMINDER = 'CANCEL_APPOINTMENT_REMINDER';
export const REVERSE_APPOINTMENT_REMINDER_CANCELLATION =
  'REVERSE_APPOINTMENT_REMINDER_CANCELLATION';
export const RESCHEDULE_APPOINTMENT_REMINDER =
  'RESCHEDULE_APPOINTMENT_REMINDER';
// Human intervention needed
export const GET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION =
  'GET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION';
export const SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION =
  'SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION';
export const SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION_PAGE =
  'SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION_PAGE';
export const SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION_LOADING =
  'SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION_LOADING';
export const SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION_ORDER =
  'SET_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION_ORDER';
// other
export const SET_SCHEDULED_STAFF_APPOINTMENTS_PAGE =
  'SET_SCHEDULED_STAFF_APPOINTMENTS_PAGE';
export const SET_PAST_STAFF_APPOINTMENTS_PAGE =
  'SET_PAST_STAFF_APPOINTMENTS_PAGE';
