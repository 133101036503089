export const PASSWORD_STATUSES = {
  EXPIRED: 'EXP',
  NOT_CHANGED: 'NCNG',
  CHANGED: 'CNG',
};

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
};
