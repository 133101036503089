// appointment
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const DELETE_APPOINTMENT_FROM_PATIENTS =
  'DELETE_APPOINTMENT_FROM_PATIENTS';
export const DELETE_APPOINTMENT_FROM_STAFF = 'DELETE_APPOINTMENT_FROM_STAFF';
export const UPDATE_APPOINTMENT_COMMUNICATION_STATUS =
  'UPDATE_APPOINTMENT_COMMUNICATION_STATUS';
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const END_APPOINTMENT = 'END_APPOINTMENT';

// doctor appointments
export const SET_DOCTOR_APPOINTMENTS = 'SET_DOCTOR_APPOINTMENTS';
export const GET_DOCTOR_APPOINTMENTS = 'GET_DOCTOR_APPOINTMENTS';

// date appointments
export const SET_DATE_APPOINTMENTS = 'SET_DATE_APPOINTMENTS';
export const GET_DATE_APPOINTMENTS = 'GET_DATE_APPOINTMENTS';
export const SET_IS_LOADING = 'SET_APPOINTMENTS_IS_LOADING';

// single appointment
export const SET_SINGLE_APPOINTMENT = 'SET_SINGLE_APPOINTMENT';
export const GET_SINGLE_APPOINTMENT = 'GET_SINGLE_APPOINTMENT';
export const SET_SINGLE_APPOINTMENT_LOADING = 'SET_SINGLE_APPOINTMENT_LOADING';

// single pre-appointment questionnaire (answers)
export const SET_SINGLE_PRE_APPOINTMENT_QUESTIONNAIRE =
  'SET_SINGLE_PRE_APPOINTMENT_QUESTIONNAIRE';
export const GET_SINGLE_PRE_APPOINTMENT_QUESTIONNAIRE =
  'GET_SINGLE_PRE_APPOINTMENT_QUESTIONNAIRE';
export const SET_SINGLE_PRE_APPOINTMENT_QUESTIONNAIRE_LOADING =
  'SET_SINGLE_PRE_APPOINTMENT_QUESTIONNAIRE_LOADING';

// doctors
export const GET_DOCTORS = 'GET_DOCTORS';
export const APPEND_TO_ALL_DOCTORS = 'APPEND_TO_ALL_DOCTORS';
export const SET_LOADING_DOCTORS = 'SET_LOADING_DOCTORS';

// appointment types
export const GET_APPOINTMENT_TYPES = 'GET_APPOINTMENT_TYPES';
export const SET_APPOINTMENT_TYPES = 'SET_APPOINTMENT_TYPES';
export const SET_APPOINTMENT_TYPES_LOADING = 'SET_APPOINTMENT_TYPES_LOADING';

// appointment statuses
export const GET_APPOINTMENT_STATUSES = 'GET_APPOINTMENT_STATUSES';
export const SET_APPOINTMENT_STATUSES = 'SET_APPOINTMENT_STATUSES';
export const SET_APPOINTMENT_STATUSES_LOADING =
  'SET_APPOINTMENT_STATUSES_LOADING';

// appointment communication statuses
export const GET_APPOINTMENT_COMMUNICATION_STATUSES =
  'GET_APPOINTMENT_COMMUNICATION_STATUSES';
export const SET_APPOINTMENT_COMMUNICATION_STATUSES =
  'SET_APPOINTMENT_COMMUNICATION_STATUSES';
export const SET_APPOINTMENT_COMMUNICATION_STATUSES_LOADING =
  'SET_APPOINTMENT_COMMUNICATION_STATUSES_LOADING';

// appointment missing reasons
export const GET_APPOINTMENT_MISSING_REASONS =
  'GET_APPOINTMENT_MISSING_REASONS';
export const SET_APPOINTMENT_MISSING_REASONS =
  'SET_APPOINTMENT_MISSING_REASONS';
export const SET_APPOINTMENT_MISSING_REASONS_LOADING =
  'SET_APPOINTMENT_MISSING_REASONS_LOADING';

// appointment cancellation reasons
export const GET_APPOINTMENT_CANCELLATION_REASONS =
  'GET_APPOINTMENT_CANCELLATION_REASONS';
export const SET_APPOINTMENT_CANCELLATION_REASONS =
  'SET_APPOINTMENT_CANCELLATION_REASONS';
export const SET_APPOINTMENT_CANCELLATION_REASONS_LOADING =
  'SET_APPOINTMENT_CANCELLATION_REASONS_LOADING';

// message requiring immediate attention statuses
export const GET_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUSES =
  'GET_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUSES';
export const SET_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUSES =
  'SET_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUSES';
export const SET_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUSES_LOADING =
  'SET_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUSES_LOADING';
export const UPDATE_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUS =
  'UPDATE_MESSAGE_REQUIRING_IMMEDIATE_ATTENTION_STATUS';

// appointment status save
export const SAVE_APPOINTMENT_STATUS = 'SAVE_APPOINTMENT_STATUS';
export const SAVE_APPOINTMENT_STATUS_LOADING =
  'SAVE_APPOINTMENT_STATUS_LOADING';

// patient
export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const SET_PATIENTS_AUTOCOMPLETE = 'SET_PATIENTS_AUTOCOMPLETE';
export const SET_PATIENTS_LOADING_AUTOCOMPLETE =
  'SET_PATIENTS_LOADING_AUTOCOMPLETE';
export const FILTER_DELETED_APPOINTMENT = 'FILTER_DELETED_APPOINTMENT';
export const RESET_PATIENTS_AUTOCOMPLETE = 'RESET_PATIENTS_AUTOCOMPLETE';
export const APPEND_MORE_PATIENTS_AUTOCOMPLETE =
  'APPEND_MORE_PATIENTS_AUTOCOMPLETE';
export const GET_MORE_SEARCH_RESULTS = 'GET_MORE_SEARCH_RESULTS';

// appointment reminders
export const SET_APPOINTMENTS_REMINDERS_PAGE_LOADING =
  'SET_APPOINTMENTS_REMINDERS_PAGE_LOADING';
export const GET_APPOINTMENTS_REMINDERS_PAGE =
  'GET_APPOINTMENTS_REMINDERS_PAGE';
export const SET_APPOINTMENTS_REMINDERS_PAGE =
  'SET_APPOINTMENTS_REMINDERS_PAGE';
export const SET_APPOINTMENTS_REMINDERS_SORT_ORDER =
  'SET_APPOINTMENTS_REMINDERS_SORT_ORDER';
export const CANCEL_APPOINTMENT_REMINDER = 'CANCEL_APPOINTMENT_REMINDER';
export const REVERSE_APPOINTMENT_REMINDER_CANCELLATION =
  'REVERSE_APPOINTMENT_REMINDER_CANCELLATION';
