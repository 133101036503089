export const GET_SINGLE_CHAT = 'GET_SINGLE_CHAT';
export const SET_SINGLE_CHAT = 'SET_SINGLE_CHAT';
export const GET_ALL_CHATS_INFO = 'GET_ALL_CHATS_INFO';
export const SET_ALL_CHATS_INFO = 'SET_ALL_CHATS_INFO';
export const SET_SINGLE_CHAT_LOADING = 'SET_SINGLE_CHAT_LOADING';
export const SET_ALL_CHATS_INFO_LOADING = 'SET_ALL_CHATS_INFO_LOADING';
export const ADD_MORE_TO_SINGLE_CHAT = 'ADD_MORE_TO_SINGLE_CHAT';
export const ADD_MORE_TO_ALL_CHATS_INFO = 'ADD_MORE_TO_ALL_CHATS_INFO';
export const GET_MORE_CHATS_INFO = 'GET_MORE_CHATS_INFO';
export const GET_MORE_SINGLE_CHAT_MESSAGES = 'GET_MORE_SINGLE_CHAT_MESSAGES';
export const SEARCH_CONVERSATIONS = 'SEARCH_CONVERSATIONS';
export const SET_CONVERSATION_TO_READ = 'SET_CONVERSATION_TO_READ';
export const TOGGLE_RASA_ACTIVITY = 'TOGGLE_RASA_ACTIVITY';
export const ADD_ONE_MESSAGE = 'ADD_ONE_MESSAGE';
export const SEND_MASS_INVITE = 'SEND_MASS_INVITE';
export const RESET_CHAT_REDUCER = 'RESET_CHAT_REDUCER';
export const TRIGGER_SEARCH_CONVERSATIONS = 'TRIGGER_SEARCH_CONVERSATIONS';
export const CLEAR_TRIGGER_SEARCH_CONVERSATIONS =
  'CLEAR_TRIGGER_SEARCH_CONVERSATIONS';
