export const GET_MEDICAL_CONDITIONS = 'GET_MEDICAL_CONDITIONS';
export const SET_MEDICAL_CONDITIONS = 'SET_MEDICAL_CONDITIONS';
export const GET_PREVIOUS_OPERATIONS = 'GET_PREVIOUS_OPERATIONS';
export const SET_PREVIOUS_OPERATIONS = 'SET_PREVIOUS_OPERATIONS';
export const SET_ANEMNESIS_LOADING = 'SET_ANEMNESIS_LOADING';
export const SET_ANEMNESIS_PAGE = 'SET_ANEMNESIS_PAGE';
export const CREATE_MEDICAL_CONDITION = 'CREATE_MEDICAL_CONDITION';
export const DELETE_MEDICAL_CONDITION = 'DELETE_MEDICAL_CONDITION';
export const APPEND_EXISTING_CONDITIONS = 'APPEND_EXISTING_CONDITIONS';
export const RESET_EXISTING_MEDICAL_CONDITION =
  'RESET_EXISTING_MEDICAL_CONDITION';
export const ADD_OPERATION_TYPE = 'ADD_OPERATION_TYPE';
export const APEND_OPERATION = 'APEND_OPERATION';
export const APPEND_OPERATIONS = 'APPEND_OPERATIONS';
export const RESET_PREVIOUS_OPERATIONS = 'RESET_PREVIOUS_OPERATIONS';
export const FILTER_OPERATION = 'FILTER_OPERATION';
export const DELETE_OPERATION_TYPE = 'DELETE_OPERATION_TYPE';
export const FILTER_OPERATION_TYPE = 'FILTER_OPERATION_TYPE';
