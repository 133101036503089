export const UPDATED_INDUSTRY_AVERAGE = 'UPDATED_INDUSTRY_AVERAGE';
export const UPDATED_INDUSTRY_AVERAGE_ERROR = 'UPDATED_INDUSTRY_AVERAGE_ERROR';
export const UPDATED_INDUSTRY_AVERAGE_SUCCESS =
  'UPDATED_INDUSTRY_AVERAGE_SUCCESS';

export const GET_INDUSTRY_AVERAGE = 'GET_INDUSTRY_AVERAGE';
export const GET_INDUSTRY_AVERAGE_SUCCESS = 'GET_INDUSTRY_AVERAGE_SUCCESS';
export const GET_INDUSTRY_AVERAGE_ERROR = 'GET_INDUSTRY_AVERAGE_ERROR';

export const CREATED_INDUSTRY_AVERAGE = 'CREATED_INDUSTRY_AVERAGE';
export const CREATED_INDUSTRY_AVERAGE_ERROR = 'CREATED_INDUSTRY_AVERAGE_ERROR';
export const CREATED_INDUSTRY_AVERAGE_SUCCESS =
  'CREATED_INDUSTRY_AVERAGE_SUCCESS';

export const UPDATE_IS_UPDATED = 'UPDATE_IS_UPDATED';
export const SET_LOADING = 'SET_LOADING_INDUSTRY_AVERAGE';
