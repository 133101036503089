export const GET_PATIENTS = 'GET_PATIENTS';
export const SET_PATIENTS = 'SET_PATIENTS';
export const GET_PATIENTS_DETAILS = 'GET_PATIENTS_DETAILS';
export const SET_PATIENT_PAGE = 'SET_PATIENT_PAGE';
export const SET_PATIENT_LOADING = 'SET_PATIENT_LOADING';
export const SET_PATIENT_ORDER = 'SET_PATIENT_ORDER';
export const SET_PATIENT_SEARCH = 'SET_PATIENT_SEARCH';
export const DELETE_PATIENT = 'DELETE_PATIENT';
export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS';
export const GET_PATIENT_DETAILS_NEW_PATIENT_FORM =
  'GET_PATIENT_DETAILS_NEW_PATIENT_FORM';
export const SET_PATIENT_DETAILS_NEW_PATIENT_FORM =
  'SET_PATIENT_DETAILS_NEW_PATIENT_FORM';
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const GET_PATIENT_SINGLE = 'GET_PATIENT_SINGLE';
export const SET_PATIENT_SINGLE = 'SET_PATIENT_SINGLE';
export const MODIFY_PATIENT = 'MODIFY_PATIENT';
export const GET_PATIENT_OVERVIEW = 'GET_PATIENT_OVERVIEW';
export const SET_SCHEDULED_APPOINTMENTS_LOADING =
  'SET_SCHEDULED_APPOINTMENTS_LOADING';
export const SET_APPOINTMENT_HISTORY_LOADING =
  'SET_APPOINTMENT_HISTORY_LOADING';
export const SET_SCHEDULED_APPOINTMENTS = 'SET_SCHEDULED_APPOINTMENTS';
export const SET_APPOINTMENT_HISTORY = 'SET_APPOINTMENT_HISTORY';
export const SET_SCHEDULED_PAGE = 'SET_SCHEDULED_PAGE';
export const SET_SCHEDULED_ORDER = 'SET_SCHEDULED_ORDER';
export const SET_HISTORY_PAGE = 'SET_HISTORY_PAGE';
export const CHANGE_PATIENT = 'CHANGE_PATIENT';
export const TOGGLE_PATIENT_WHITELIST = 'TOGGLE_PATIENT_WHITELIST';
export const GET_APPOINTMENT_HISTORY = 'GET_APPOINTMENT_HISTORY';
export const GET_SCHEDULED_APPOINTMENTS = 'GET_SCHEDULED_APPOINTMENTS';
export const MARK_CONVERSATION_HUMAN_NOT_REQUIRED =
  'MARK_CONVERSATION_HUMAN_NOT_REQUIRED';
export const MARK_CONVERSATION_NOT_IN_EMERGENCY_SITUATION =
  'MARK_CONVERSATION_NOT_IN_EMERGENCY_SITUATION';
export const SET_PATIENT_SHOW_MESSAGES = 'SET_PATIENT_SHOW_MESSAGES';
export const CLEAR_PATIENT_SHOW_MESSAGES = 'CLEAR_PATIENT_SHOW_MESSAGES';
