export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const AUTH_TOKEN = 'token';
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE';
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED =
  'SIGNIN_WITH_GOOGLE_AUTHENTICATED';
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK';
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED =
  'SIGNIN_WITH_FACEBOOK_AUTHENTICATED';
export const SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL';
export const SEND_FORGOT_PASSWORD_EMAIL_SUCCESS =
  'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS';
export const SEND_FORGOT_PASSWORD_EMAIL_ERROR =
  'SEND_FORGOT_PASSWORD_EMAIL_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const FETCH_USER = 'FETCH_USER';
export const SET_USER = 'SET_USER';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const SET_PASSWORD_CHANGED = 'SET_PASSWORD_CHANGED';
export const SET_TOKEN = 'SET_TOKEN';
export const INVALID_TOKEN_CODE = 'token_not_valid';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
