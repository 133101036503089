const dev = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  SOCKETS_DOMAIN: process.env.REACT_APP_SOCKETS_DOMAIN,
  URL_PREFIX_PATH: process.env.REACT_APP_URL_PREFIX_PATH,
  SHOW_APPOINTMENTS_LIKELY_TO_BE_MISSED:
    process.env.REACT_APP_SHOW_APPOINTMENTS_LIKELY_TO_BE_MISSED,
  SHOW_PASSED_APPOINTMENTS_REQUIRING_IMMEDIATE_ATTENTION:
    process.env
      .REACT_APP_SHOW_PASSED_APPOINTMENTS_REQUIRING_IMMEDIATE_ATTENTION,
  SHOW_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION:
    process.env.REACT_APP_SHOW_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION,
  SHOW_APPOINTMENTS_REMINDERS:
    process.env.REACT_APP_SHOW_APPOINTMENTS_REMINDERS,
  SHOW_KPIS: process.env.REACT_APP_SHOW_KPIS,
};

const prod = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  SOCKETS_DOMAIN: process.env.REACT_APP_SOCKETS_DOMAIN,
  URL_PREFIX_PATH: process.env.REACT_APP_URL_PREFIX_PATH,
  SHOW_APPOINTMENTS_LIKELY_TO_BE_MISSED:
    process.env.REACT_APP_SHOW_APPOINTMENTS_LIKELY_TO_BE_MISSED,
  SHOW_PASSED_APPOINTMENTS_REQUIRING_IMMEDIATE_ATTENTION:
    process.env
      .REACT_APP_SHOW_PASSED_APPOINTMENTS_REQUIRING_IMMEDIATE_ATTENTION,
  SHOW_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION:
    process.env.REACT_APP_SHOW_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION,
  SHOW_APPOINTMENTS_REMINDERS:
    process.env.REACT_APP_SHOW_APPOINTMENTS_REMINDERS,
  SHOW_KPIS: process.env.REACT_APP_SHOW_KPIS,
};

const test = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_URL,
  SOCKETS_DOMAIN: process.env.REACT_APP_SOCKETS_DOMAIN,
  URL_PREFIX_PATH: process.env.REACT_APP_URL_PREFIX_PATH,
  SHOW_APPOINTMENTS_LIKELY_TO_BE_MISSED:
    process.env.REACT_APP_SHOW_APPOINTMENTS_LIKELY_TO_BE_MISSED,
  SHOW_PASSED_APPOINTMENTS_REQUIRING_IMMEDIATE_ATTENTION:
    process.env
      .REACT_APP_SHOW_PASSED_APPOINTMENTS_REQUIRING_IMMEDIATE_ATTENTION,
  SHOW_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION:
    process.env.REACT_APP_SHOW_MESSAGES_REQUIRING_IMMEDIATE_ATTENTION,
  SHOW_APPOINTMENTS_REMINDERS:
    process.env.REACT_APP_SHOW_APPOINTMENTS_REMINDERS,
  SHOW_KPIS: process.env.REACT_APP_SHOW_KPIS,
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
