import languageReducer from 'containers/LanguageProvider/reducer';
import { combineReducers } from 'redux';
import auth from './Auth';
import industryAverage from './IndustryAverage';
import clinic from './Clinic';
import theme from './Theme';
import staff from './Staff';
import patient from './Patient';
import user from './User';
import appointment from './Appointment';
import anemnesis from './Anemnesis';
import chats from './Chats';
import overview from './Overview';
import { connectRouter } from 'connected-react-router';
import { SIGNOUT_SUCCESS } from 'redux/constants/Auth';

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) => {
  const combinedReducer = combineReducers({
    router: connectRouter(history),
    theme,
    auth,
    industryAverage,
    clinic,
    staff,
    patient,
    user,
    appointment,
    anemnesis,
    chats,
    overview,
    language: languageReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === SIGNOUT_SUCCESS) {
      state = undefined;
    }
    return combinedReducer(state, action);
  };
  return rootReducer;
};
